import './loader.css'

import { cn } from '../../utils/cn'

const Loader = ({
  colorClass,
  className,
}: {
  colorClass?: string
  className?: string
}) => {
  const css = () => {
    return colorClass || 'bg-white'
  }
  return (
    <div className={cn('lds-ellipsis', className)}>
      <div className={cn(css())}></div>
      <div className={cn(css())}></div>
      <div className={cn(css())}></div>
      <div className={cn(css())}></div>
    </div>
  )
}

export { Loader }
