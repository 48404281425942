/** Announces to screen readers. Use for accessibility to explain when elements on the page change.
 *
 * @param message what you want the users to hear
 * @param level defaults to polite, pass in 'assertive' to cut off what the screen reader is saying and say your message instead. Use assertive sparingly
 */
const ariaSpeak = (message: string, level?: 'assertive' | 'polite') => {
  var el = document.createElement('div')
  var id = 'speak-' + Date.now()
  el.setAttribute('id', id)
  el.setAttribute('aria-live', level || 'polite')
  el.style.cssText =
    'position: absolute; top: 0; left: -1000px; width: 1px; height: 1px; z-index: -1; overflow: hidden;'
  document.body.appendChild(el)

  window.setTimeout(function () {
    const element = document.getElementById(id)
    if (element !== null) {
      element.innerHTML = message
    }
  }, 100)

  window.setTimeout(function () {
    const element = document.getElementById(id)
    if (element !== null) {
      document.body.removeChild(element)
    }
  }, 1000)
}

export { ariaSpeak }
