'use client'

import React, { useEffect } from 'react'
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react'
import AnalyticsProvider from '@cerifi/analytics/analytics-provider'
import ToastProvider from '@cerifi/ui/components/toast/toast-provider'

interface ProvidersProps extends React.PropsWithChildren {
  draftMode: boolean
}

const analyticsSettings = {
  CONFIG: {
    GOOGLE_TAG_MANAGER: {
      KEY: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || '',
    },
    GOOGLE_TAG_MANAGER_SECONDARY: {
      KEY: '',
    },
    META_PIXEL: {
      KEY: process.env.NEXT_PUBLIC_META_PIXEL_ID || '',
    },
  },
}

export const CerifiProviders = ({ children, draftMode }: ProvidersProps) => {
  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_DATADOG_ENABLED) return

    const init = async () => {
      const initDatadog = (await import('@cerifi/utils/datadog')).default
      initDatadog()
    }
    init()
  }, [])

  return (
    <React.Fragment>
      <ToastProvider />

      <ContentfulLivePreviewProvider
        locale="en-US"
        enableInspectorMode={draftMode || false}
        enableLiveUpdates={draftMode || false}
      >
        <AnalyticsProvider settings={analyticsSettings}>
          {children}
        </AnalyticsProvider>
      </ContentfulLivePreviewProvider>
    </React.Fragment>
  )
}
