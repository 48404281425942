'use client'

import React, { Fragment, useEffect, useState } from 'react'

import contentfulImage from '../../utils/contentful-image'

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  /** Loads in first */
  pixelatedUrl?: string
  /** Makes it div instead of slot */
  isBackgroundImg?: boolean
}

const SmartImage = React.forwardRef<HTMLImageElement, ImageProps>(
  (
    { pixelatedUrl, isBackgroundImg, src, children, ...props }: ImageProps,
    ref
  ) => {
    const formattedSrc = contentfulImage(src as string)
    const initialSrc = pixelatedUrl
      ? contentfulImage(pixelatedUrl as string)
      : formattedSrc
    const [_src, _setSrc] = useState(initialSrc)

    useEffect(() => {
      const loadImage = async () => {
        if (!pixelatedUrl) return

        const img = new Image()
        img.src = formattedSrc

        img.onload = () => _setSrc(formattedSrc)
      }

      loadImage()
    }, [pixelatedUrl, formattedSrc])

    return (
      <Fragment>
        {isBackgroundImg ? (
          <div
            {...props}
            ref={ref}
            role="presentation"
            style={{
              ...props.style,
              backdropFilter: _src === formattedSrc ? '' : 'blur(10px)',
              backgroundImage: _src ? `url("${_src}")` : undefined,
              transition: 'backdrop-filter 0.2s ease-out',
            }}
          >
            {children}
          </div>
        ) : (
          <img
            style={{
              filter: _src === initialSrc ? '' : 'blur(10px)',
              transition: 'filter 0.2s ease-out',
            }}
            ref={ref}
            {...props}
            alt={props.alt || ''}
            src={_src}
          />
        )}
      </Fragment>
    )
  }
)
SmartImage.displayName = 'Image'

export { SmartImage }
