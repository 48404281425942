import Snippets from '../snippets'

declare global {
  // tslint:disable-next-line
  interface Window {
    dataLayer: any
    fbp: any
  }
}

const MetaPixelInit = {
  pixel(args: any) {
    if (!document) {
      return null
    }
    const snippets = Snippets.pixel(args)

    const script = () => {
      const element = document.createElement('script')
      element.innerHTML = snippets.script
      return element
    }

    return {
      script,
    }
  },
  initialize(id: any) {
    const pixel = this.pixel({
      id,
    })

    if (!pixel) {
      return
    }

    if (document.head.childNodes[0]) {
      const script = pixel.script()
      document.head.insertBefore(script, document.head.childNodes[0])
    }
  },
}

export default MetaPixelInit
