'use client'

import Image from 'next/image'
import Link from 'next/link'
import { StorefrontConfig } from '@cerifi/contentful/__generated/graphql.types'
import { IconButton } from '@cerifi/ui/components/icon-button'
import { MenuIcon } from '@cerifi/ui/components/icons'
import { cn } from '@cerifi/ui/utils/cn'

export const CerifiMobileNavBar = ({
  config,
  className,
  openMobileNav,
}: {
  config: StorefrontConfig
  className?: string
  openMobileNav: () => void
}) => {
  return (
    <nav
      className={cn(
        'flex h-[4.25rem] w-full items-center gap-5 bg-white pl-[2px] text-black',
        className
      )}
    >
      <IconButton
        variant="ghost"
        noTooltip
        onClick={() => openMobileNav()}
        aria-label="Mobile navigation menu"
      >
        <MenuIcon className="h-10" />
      </IconButton>

      <Link href="/" aria-label="home" data-testid="desktop-home-link">
        {config.logo?.url ? (
          <Image
            src={config.logo.url}
            alt={config.logo.description || config.storefrontName || 'Logo'}
            width="124"
            height="28"
            priority
            className="max-w-[16rem] xl:max-w-none"
          />
        ) : (
          <>{config.storefrontName}</>
        )}
      </Link>
    </nav>
  )
}
