'use client'

import * as ToastPrimitive from '@radix-ui/react-toast'
import { cva } from 'class-variance-authority'
import useNotificationStore, {
  NotificationLevel,
  Toast as ToastModel,
} from '@cerifi/state/notification.store'
import { cn } from '@cerifi/ui/utils/cn'

import { IconButton } from '../icon-button'
import { AlertCircleIcon, CheckCircleIcon, CloseIcon, InfoIcon } from '../icons'

const toastVariants = cva('', {
  variants: {
    variant: {
      default: 'bg-neutral-800',
      info: 'bg-secondary-300',
      success: 'bg-success-700',
      error: 'bg-error-800',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

export const Toast = ({ toast }: { toast: ToastModel }) => {
  const removeToast = useNotificationStore((state) => state._removeToast)

  const renderIcon = (level: NotificationLevel | undefined) => {
    switch (level) {
      case 'info':
        return <InfoIcon />
      case 'error':
        return <AlertCircleIcon />
      case 'success':
        return <CheckCircleIcon />
      case 'default':
      default:
        return null
    }
  }

  return (
    <ToastPrimitive.Root
      className={cn(
        toastVariants({ variant: toast.level }),
        "data-[state=open]:animate-slideIn data-[swipe=end]:animate-swipeOut p-3.75 flex items-center gap-3 rounded-md text-white shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] [grid-template-areas:_'title_action'_'description_action'] data-[swipe=cancel]:translate-x-0 data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:transition-[transform_200ms_ease-out] print:hidden"
      )}
      duration={toast.duration}
      open={true}
      onOpenChange={() => removeToast(toast)}
    >
      <div>{renderIcon(toast.level)}</div>
      <div className="flex-1">
        <ToastPrimitive.Title>{toast.title}</ToastPrimitive.Title>

        {toast.description && (
          <ToastPrimitive.Description className="mt-2" asChild>
            <div>{toast.description}</div>
          </ToastPrimitive.Description>
        )}
      </div>

      {toast.canHide !== false && (
        <ToastPrimitive.Action asChild altText="Close">
          <IconButton aria-label="close" variant="ghost">
            <CloseIcon />
          </IconButton>
        </ToastPrimitive.Action>
      )}
    </ToastPrimitive.Root>
  )
}
