import React from 'react'

/** IMPORTANT: Cant read prop values because it uses addEventListener. Use refs if needed */
export function useClickOutside(callBack: () => void) {
  const ref = React.useRef<HTMLDivElement>(null)

  const handler = React.useRef<Function>()

  React.useEffect(() => {
    handler.current = callBack
  }, [callBack])

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        if (handler.current) {
          handler.current()
        }
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return ref
}
