'use client'

import * as ToastPrimitive from '@radix-ui/react-toast'
import useNotificationStore from '@cerifi/state/notification.store'

import { Toast } from './toast-component'

const ToastProvider = () => {
  const toasts = useNotificationStore((state) => state._toasts)

  return (
    <ToastPrimitive.Provider swipeDirection="right">
      {toasts.map((toast, i) => (
        <Toast toast={toast} key={toast.uid} />
      ))}

      <ToastPrimitive.Viewport className="fixed bottom-0 right-0 z-[2147483647] m-0 flex w-[42.5rem] max-w-[100vw] list-none flex-col gap-2.5 p-[var(--viewport-padding)] outline-none [--viewport-padding:_1.5625rem]" />
    </ToastPrimitive.Provider>
  )
}

export default ToastProvider
