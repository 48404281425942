'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { Button } from '@cerifi/ui/components/button'

export const DraftModeBanner = () => {
  const pathname = usePathname()

  return (
    <header className="bg-background sticky top-0 z-[101]">
      <div className="container flex h-12 items-center justify-between py-4">
        <div>DRAFT MODE</div>
        <Button className="px-6" size={'sm'}>
          <Link prefetch={false} href={`/api/disable-draft?url=${pathname}`}>
            EXIT PREVIEW
          </Link>
        </Button>
      </div>
    </header>
  )
}
