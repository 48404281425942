import React from 'react'
import Link from 'next/link'
import { cva, VariantProps } from 'class-variance-authority'

import { cn } from '../../utils/cn'
import { Loader } from '../loader'

const buttonVariants = cva(
  'inline-flex text-center items-center justify-center font-semibold rounded-full transition-colors disabled:opacity-50 disabled:pointer-events-none data-[state=open]:bg-neutral-100',
  {
    variants: {
      variant: {
        default: 'bg-secondary text-white hover:bg-secondary-700',
        white: 'bg-white text-black hover:bg-neutral-300',
        outline:
          'bg-transparent border-2 border-navy text-navy hover:bg-navy hover:text-white',
        outlineLight:
          'bg-transparent border-2 border-white text-white hover:bg-navy',
        secondary: 'bg-white text-navy hover:bg-backgroundShade-50',
        subtle: 'bg-neutral-100 text-neutral-900 hover:bg-neutral-200',
        ghost: 'bg-transparent',
        link: 'bg-transparent underline-offset-4 hover:underline hover:bg-transparent',
      },
      size: {
        default: 'min-h-[3rem] py-2 px-10 lg:px-14',
        sm: 'min-h-[2.25rem] px-2',
        lg: 'min-h-[3.5rem] px-10 lg:px-14',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  isLoading?: boolean
}

interface ButtonLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof buttonVariants> {}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, isLoading, children, disabled, ...props },
    ref
  ) => {
    return (
      <button
        className={cn(
          buttonVariants({ variant, size, className }),
          variant === 'link' && 'px-0 lg:px-0',
          'relative'
        )}
        ref={ref}
        {...props}
        disabled={disabled || isLoading}
      >
        <div className={cn(isLoading && 'invisible')}>{children}</div>

        {isLoading && (
          <div className="absolute inset-0 z-10 flex items-center justify-center">
            <Loader
              colorClass={variant === 'ghost' ? 'bg-periwinkle' : 'bg-white'}
            />
          </div>
        )}
      </button>
    )
  }
)

// eslint-disable-next-line react/display-name
export const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  ({ className, variant, size, children, ...props }, ref) => {
    return (
      <Link
        className={cn(
          buttonVariants({ variant, size, className }),
          variant === 'link' && 'px-0 lg:px-0',
          'relative'
        )}
        ref={ref}
        {...(props as any)}
      >
        {children}
      </Link>
    )
  }
)

Button.displayName = 'Button'

export { Button, buttonVariants }
