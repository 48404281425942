'use client'

import Snippets from '../snippets'

const TagManagerInit = {
  gtm(args: any) {
    if (!document) {
      return null
    }
    const snippets = Snippets.tags(args)

    const script = () => {
      const element = document.createElement('script')
      element.innerHTML = snippets.script
      return element
    }

    return {
      script,
    }
  },
  initialize(id: string) {
    const gtm = this.gtm({
      id,
    })

    if (!gtm) {
      return
    }

    const script = gtm.script()

    if (document.head.childNodes[0]) {
      document.head.insertBefore(script, document.head.childNodes[0])
    }
  },
  initializeDataLayer() {
    const dataLayer = 'window.dataLayer = window.dataLayer || []'
    const element = document.createElement('script')
    element.innerHTML = dataLayer

    if (document.head.childNodes[0]) {
      document.head.appendChild(element)
    }
  },
}

export default TagManagerInit
