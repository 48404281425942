export enum CerifiRoutes {
  Home = '/',
  AboutUs = '/about-us',
  TechAndInnovation = '/tech-and-innovation',
  Careers = 'https://recruiting.paylocity.com/recruiting/jobs/All/7b7bf054-90d2-4b6b-89ec-50005d606864/CeriFi',
  News = '/news',
  ForTeams = '/for-teams',
  OurBrands = '/our-brands',
  Contact = '/contact-us',
}
