import { v4 } from 'uuid'
import { create } from 'zustand'

export type NotificationLevel = 'default' | 'info' | 'success' | 'error'

export interface Toast {
  title: string
  description?: string
  canHide?: boolean
  level?: NotificationLevel
  duration?: number
}

interface _Toast extends Toast {
  uid: string
}

interface NotificationStore {
  draftMode: boolean
  setDraftMode: (enabled: boolean) => void
  showToast: (toast: Toast) => void

  /** Only the ToastProvider component should use this... */
  _toasts: _Toast[]
  /** Only the ToastProvider component should use this... */
  _removeToast: (toast: Toast) => void
  /** Shows error toast */
  handleError: (message: string | null | undefined) => void
}

/** Toast Provider will watch these toast's and show them in the UI, */
const useNotificationStore = create<NotificationStore>((set, get) => ({
  _toasts: [],
  draftMode: false,
  setDraftMode: (enabled: boolean) => {
    set({ draftMode: enabled })
  },
  showToast: (toast: Toast) => {
    const t: _Toast = {
      ...toast,
      uid: v4(),
    }
    set({
      _toasts: get()._toasts.concat([t]),
    })
  },
  _removeToast: (toast: Toast) => {
    set({
      _toasts: get()._toasts.filter((x) => x !== toast),
    })
  },
  handleError: (message) => {
    get().showToast({
      title: message || 'Something went wrong',
      canHide: true,
      level: 'error',
    })
  },
}))

export default useNotificationStore
