/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-indent */
import { useEffect } from 'react'

import TagManager from './gtm/tag-manager-init'
import MetaPixelInit from './pixel/meta-pixel-init'

export interface AnalyticsSettings {
  CONFIG: {
    GOOGLE_TAG_MANAGER: {
      KEY: string
    }
    GOOGLE_TAG_MANAGER_SECONDARY: {
      KEY: string
    }
    META_PIXEL: {
      KEY: string
    }
  }
}

export interface IAnalyticsProviderProps {
  children: React.ReactNode
  settings: AnalyticsSettings
}

export interface IGoogleProviderProps {
  children: React.ReactNode
  settings: AnalyticsSettings
}
export interface IMetaPixelProviderProps {
  children: React.ReactNode
  settings: AnalyticsSettings
}

const AnalyticsProvider: React.FC<IAnalyticsProviderProps> = ({
  children,
  settings,
}) =>
  settings ? (
    <GoogleProvider settings={settings}>
      <MetaPixelProvider settings={settings}>{children}</MetaPixelProvider>
    </GoogleProvider>
  ) : null

export default AnalyticsProvider

const GoogleProvider: React.FC<IGoogleProviderProps> = ({
  children,
  settings,
}: {
  children: React.ReactNode
  settings: AnalyticsSettings
}) => {
  useEffect(() => {
    if (settings.CONFIG.GOOGLE_TAG_MANAGER.KEY) {
      TagManager.initialize(settings.CONFIG.GOOGLE_TAG_MANAGER.KEY)
    }
    if (settings.CONFIG.GOOGLE_TAG_MANAGER_SECONDARY.KEY) {
      TagManager.initialize(settings.CONFIG.GOOGLE_TAG_MANAGER_SECONDARY.KEY)
    }

    if (
      settings.CONFIG.GOOGLE_TAG_MANAGER ||
      settings.CONFIG.GOOGLE_TAG_MANAGER_SECONDARY
    ) {
      TagManager.initializeDataLayer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{children}</>
}

const MetaPixelProvider: React.FC<IMetaPixelProviderProps> = ({
  children,
  settings,
}: {
  children: React.ReactNode
  settings: AnalyticsSettings
}) => {
  useEffect(() => {
    if (settings.CONFIG.META_PIXEL.KEY) {
      MetaPixelInit.initialize(settings.CONFIG.META_PIXEL.KEY)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{children}</>
}
