'use client'

import React from 'react'
import * as Separator from '@radix-ui/react-separator'

import { cn } from '../../utils/cn'

interface DividerProps extends React.HTMLAttributes<HTMLDivElement> {
  orientation?: 'vertical' | 'horizontal'
}

const Divider = React.forwardRef<HTMLElement, DividerProps>(
  ({ orientation, className, ...props }, ref) => {
    return (
      <Separator.Root
        decorative
        orientation={orientation || 'horizontal'}
        ref={ref as any}
        className={cn(
          orientation === 'vertical'
            ? 'w-px bg-neutral-300'
            : 'h-px bg-neutral-300',
          className
        )}
        {...props}
      ></Separator.Root>
    )
  }
)
Divider.displayName = 'Divider'

export { Divider }
