'use client'

import { useEffect, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname, useSearchParams } from 'next/navigation'
import { CerifiRoutes } from '@/models/cerifi-routes'
import FocusTrap from 'focus-trap-react'
import { StorefrontConfig } from '@cerifi/contentful/__generated/graphql.types'
import { ButtonLink } from '@cerifi/ui/components/button'
import { Divider } from '@cerifi/ui/components/divider'
import { IconButton } from '@cerifi/ui/components/icon-button'
import { CloseIcon } from '@cerifi/ui/components/icons'
import { useClickOutside } from '@cerifi/ui/hooks/use-click-outside'
import { cn } from '@cerifi/ui/utils/cn'
import { ariaSpeak } from '@cerifi/utils/aria-speak'

import { CerifiMobileNavBar } from './cerifi-mobile-nav-bar'

const _NavLink = ({
  title,
  href,
  isOpen,
  bold,
}: {
  title: string
  href: string
  isOpen: boolean
  bold?: boolean
}) => (
  <li
    className={cn(
      bold ? 'font-semibold' : 'font-normal',
      'border-tan-100 border border-x-0 border-t-0 pb-[1.125rem] pt-4'
    )}
  >
    <Link
      className="hover:text-secondary focus:text-secondary"
      tabIndex={isOpen ? 0 : -1}
      href={href}
    >
      {title}
    </Link>
  </li>
)

export const CerifiMobileNav = ({
  config,
  className,
}: {
  config: StorefrontConfig
  className?: string
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    setIsOpen(false)
  }, [pathname, searchParams])

  const ref = useClickOutside(() => {
    if (isOpen) {
      setIsOpen(false)
    }
  })

  const open = () => {
    ariaSpeak('mobile navigation menu opened')
    setIsOpen(true)
  }

  const close = () => {
    ariaSpeak('mobile navigation menu closed')
    setIsOpen(false)
  }

  return (
    <div className={className}>
      <CerifiMobileNavBar config={config} openMobileNav={() => open()} />

      <div
        ref={ref}
        className={cn(
          'bg-lightTan fixed left-0 top-0 z-[1001] h-[100vh] overflow-hidden mobile-100vh-clamp'
        )}
        style={{
          transition: '200ms ease-in-out',
          width: 'min(90vw, 355px)',
          maxWidth: isOpen ? '355px' : '0px',
        }}
        role="dialog"
        aria-label="Mobile Navigation"
        aria-modal="true"
        aria-hidden={!isOpen}
      >
        <div className="h-full" style={{ width: 'min(90vw, 355px)' }}>
          <FocusTrap active={isOpen}>
            <div className="flex h-full flex-col justify-between">
              <div>
                <div className="flex items-center justify-between bg-white">
                  <div className="pb-[1.04rem] pl-[1.125rem] pt-[1.44rem]">
                    {config.logo?.url ? (
                      <Image
                        src={config.logo.url}
                        alt={
                          config.logo.description ||
                          config.storefrontName ||
                          'Logo'
                        }
                        width="124"
                        height="28"
                        priority
                        className="max-w-[16rem] xl:max-w-none"
                      />
                    ) : (
                      <>{config.storefrontName}</>
                    )}
                  </div>

                  <div className="pr-3 pt-1.5">
                    <IconButton
                      variant="link"
                      aria-label="close mobile navigation menu"
                      noTooltip
                      tabIndex={isOpen ? 0 : -1}
                      onClick={() => close()}
                    >
                      <CloseIcon size="30" />
                    </IconButton>
                  </div>
                </div>
                <Divider className="bg-tan-100" />

                <nav
                  aria-label="mobile main"
                  className="px-[1.125rem] text-start"
                >
                  <ul className="text-lg text-[#000]">
                    <_NavLink
                      title="For Teams"
                      href={CerifiRoutes.ForTeams}
                      bold
                      isOpen={isOpen}
                    />
                    <_NavLink
                      title="Our Brands"
                      href={CerifiRoutes.OurBrands}
                      bold
                      isOpen={isOpen}
                    />
                    <_NavLink
                      title="About Us"
                      href={CerifiRoutes.AboutUs}
                      isOpen={isOpen}
                    />
                    <_NavLink
                      title="Tech and Innovation"
                      href={CerifiRoutes.TechAndInnovation}
                      isOpen={isOpen}
                    />
                    <_NavLink
                      title="Careers"
                      href={CerifiRoutes.Careers}
                      isOpen={isOpen}
                    />
                    <_NavLink
                      title="News"
                      href={CerifiRoutes.News}
                      isOpen={isOpen}
                    />
                  </ul>
                </nav>
              </div>

              <div className="bg-tan-100 px-[1.875rem] py-4">
                <ButtonLink
                  className="bg-primary w-full font-semibold"
                  href={CerifiRoutes.Contact}
                  tabIndex={isOpen ? 0 : -1}
                >
                  Contact Us
                </ButtonLink>
              </div>
            </div>
          </FocusTrap>
        </div>
      </div>
    </div>
  )
}
