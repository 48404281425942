export interface ISnippets {
  tags: ({ id }: { id: string }) => {
    script: string
    noScript: string
    dataLayer: string
  }
  pixel: ({ id }: { id: string }) => {
    script: string
    noScript: string
  }
}

const Snippets: ISnippets = {
  tags({ id }) {
    if (!id) {
      throw new Error('GTM ID required')
    }

    const script = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${id}');
    `

    const noScript = `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}&gtm_cookies_win=x"
        height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`

    const dataLayer = 'window.dataLayer = window.dataLayer || []'

    return {
      script,
      noScript,
      dataLayer,
    }
  },
  pixel({ id }) {
    if (!id) {
      throw new Error('Pixel ID required')
    }

    const script = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', ${id});
      fbq('track', 'PageView');
    `

    const noScript = `<noscript><img height="1" width="1" style="display:none"
    src="https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1"
    /></noscript>`

    return {
      script,
      noScript,
    }
  },
}

export default Snippets
