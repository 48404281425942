'use client'

import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'
import { cva, VariantProps } from 'class-variance-authority'

import { cn } from '../../utils/cn'

const iconButtonVariants = cva(
  'inline-flex items-center justify-center rounded-md font-medium transition-colors focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2 dark:hover:bg-neutral-800 dark:hover:text-neutral-100 disabled:opacity-50 dark:focus:ring-neutral-400 disabled:pointer-events-none dark:focus:ring-offset-neutral-900 data-[state=open]:bg-neutral-100 dark:data-[state=open]:bg-neutral-800',
  {
    variants: {
      variant: {
        default:
          'bg-neutral-900 text-white hover:bg-neutral-700 dark:bg-neutral-300 dark:text-neutral-900',
        outline:
          'bg-transparent border border-neutral-200 hover:bg-neutral-100 dark:border-neutral-700 dark:text-neutral-100',
        subtle:
          'bg-neutral-100 text-neutral-900 hover:bg-neutral-200 dark:bg-neutral-700 dark:text-neutral-100',
        ghost:
          'bg-transparent  dark:text-neutral-100 dark:hover:text-neutral-100 data-[state=open]:bg-transparent dark:data-[state=open]:bg-transparent',
        link: 'bg-transparent underline-offset-4 hover:underline text-neutral-900 dark:text-neutral-100 hover:bg-transparent dark:hover:bg-transparent',
      },
      size: {
        default: 'h-10 w-10 rounded-full',
        sm: 'h-9 w-9 rounded-full',
        lg: 'h-11 w-11 rounded-full',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof iconButtonVariants> {
  noTooltip?: boolean
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ className, variant, size, noTooltip, ...props }, ref) => {
    useEffect(() => {
      if (!props['aria-label']) {
        throw new Error(
          'Accessibility Error: icon buttons require aria labels due to visual nature'
        )
      }
    }, [])

    const renderBtn = () => (
      <button
        className={cn(iconButtonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )

    if (noTooltip) return renderBtn()

    const Tooltip = dynamic(() => import('../tooltip'))

    return <Tooltip trigger={renderBtn()}>{props['aria-label']}</Tooltip>
  }
)
IconButton.displayName = 'IconButton'

export { IconButton, iconButtonVariants }
